import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { withIntl } from "../i18n"
import { FormattedMessage } from "react-intl"

import './designers.css'

import DesignerThumbnail from "../components/DesignerThumbnail"
import Html from '../components/Html'
import SEO from '../components/seo'

import config from '../config'

const DesignersGrid = ({ data }) => (
  <div id="designers-grid">
    { data.map(({ node }) => <DesignerThumbnail key={node.id} data={node} />) }
  </div>
)

const DesignersPage = ({ data, context }) => (
  <Layout>
    <SEO title="Designers" />
    <div className="centered side-padded vertical-padded text-centered content" style={{ paddingTop: "8rem", paddingBottom: "8rem", fontSize: "2rem" }}>
      <span style={{ display: "block", marginLeft: "-10rem" }} className="firstt">Shhh...</span>
      <span style={{ display: "block", marginRight: "-8rem" }} className="secondt">Something is coming.</span>
    </div>
  </Layout>
)

export const query = graphql`
  query ($locale: String!) {
    allContentfulDesigner (
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [name] }
    ) {
      edges {
        node {
          id
          contentful_id
          node_locale
          name
          content {
            childContentfulRichText {
              html
            }
          }
          thumbnail {
            fixed(width: 800, height: 1000) {
              src
            }
          }
        }
      }
    }
    contentfulPage (node_locale: { eq: $locale }, name: { eq: "Showroom" }) {
      name
      title
      content {
          childContentfulRichText {
              html
          }
      }
      node_locale
    }
    instagramLogo: file(relativePath: { eq: "instagram_logo.png" }) {
      childImageSharp {
        fixed(width: 128, height: 128) {
          src
        }
      }
    }
  }
`

export default withIntl(DesignersPage)
